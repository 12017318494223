[ class^=se ]

  &.se-yahoo
    color: #6001d2

  &.se-bing
    color: #00809d

  &.se-duckduckgo
    color: #de5833

  &:hover
    svg
      transform: scale( 1.4 )
